/******* screen-large.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
.togglenavigation {
  right: 50px;
  top: 37px;
}
.cb-scroll-triggered--active .togglenavigation {
  top: 22px;
}
#navigation .languageNavi {
  margin-top: 194px;
}
.cb-scroll-triggered--active #navigation .languageNavi {
  margin-top: 70px;
}
#navigation .navi.sub1 {
  margin-top: 35px;
}
#navigation .navi.sub1 > .item > .menu {
  padding: 15px 0;
  font-size: 45px;
  font-size: 4.5rem;
}
#navigation .vcard {
  margin-top: 100px;
}
.home--big {
  height: 134px;
  top: 30px;
  left: 50px;
  transition: all 250ms;
}
.cb-scroll-triggered--active .home--big {
  top: 13px;
  height: 72px;
}
.home--small {
  top: 30px;
  left: 50px;
  display: block;
  height: 80px;
  visibility: hidden;
  opacity: 0;
  transition: all 250ms;
}
.cb-scroll-triggered--active .home--small {
  top: 13px;
  height: 44px;
  visibility: visible;
  opacity: 1;
}
.home--small-mobile {
  display: none;
}
#head {
  max-width: 700px;
  margin-top: 220px;
}
.cb-layout3 #head {
  margin-top: 101px;
}
@media (max-width: 1700px) {
  .cb-layout1 #head {
    margin-top: 140px;
  }
}
.navbar {
  height: 194px;
}
.cb-scroll-triggered--active .navbar {
  height: 70px;
}
.downlink {
  float: left;
  display: block;
  width: 35px;
  height: 54px;
  margin-top: 60px;
  background: url(/images/arrow-down.svg) no-repeat center;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.wrapper {
  padding-bottom: calc(100vh - 70px);
}
.section--footer {
  top: 70px;
  height: calc(100vh - 70px);
  text-align: left;
}
.section--footer h2 {
  margin-top: 0;
  font-size: 45px;
  font-size: 4.5rem;
  line-height: 1.11111111;
}
.section--footer .footer-inner {
  box-sizing: border-box;
  align-items: center;
  padding: 30px 0 100px;
}
.section--footer .left {
  width: calc(100% - 370px);
}
.section--footer .right {
  float: right;
  width: 370px;
  text-align: right;
}
.section--footer .vcard .org {
  margin-top: 0;
}
.section--footer .logo {
  width: 135px;
  margin-right: 0;
}
.section--footer .services {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-top: 0;
}
.section--footer .services .left,
.section--footer .services .center,
.section--footer .services .right {
  width: 33%;
}
.section--footer .services .center {
  text-align: center;
}
.section--footer .services .copy,
.section--footer .services #services {
  display: inline-block;
  margin-top: 0;
}
.section--footer .services #services {
  margin: 0 40px;
}
.section--footer .services #cmsbox {
  margin-top: 0;
  text-align: right;
}
.wrapper {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
h1 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.38888889;
}
h2 {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.38888889;
}
h3 {
  font-size: 60px;
  font-size: 6rem;
  line-height: 1;
}
.cb-layout1 .farnorth h3,
.cb-layout2 .farnorth h3 {
  font-size: 90px;
  font-size: 9rem;
  line-height: 1;
}
@media (max-width: 1700px) {
  .cb-layout1 .farnorth h3,
  .cb-layout2 .farnorth h3 {
    font-size: 45px;
    font-size: 4.5rem;
    line-height: 1.11111111;
  }
}
.north h3 {
  font-size: 45px;
  font-size: 4.5rem;
  line-height: 1.11111111;
}
h4 {
  font-size: 45px;
  font-size: 4.5rem;
  line-height: 1;
}
.loud {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.38888889;
}
.area.main,
.area.base {
  width: 100%;
}
.area.main > .unit,
.area.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.main .foot,
.area.base .foot {
  margin-right: 1.37614679%;
  margin-left: 1.37614679%;
  width: 97.24770642%;
}
.area.main .part,
.area.base .part {
  margin-right: 1.37614679%;
  margin-left: 1.37614679%;
  width: 97.24770642%;
}
.area.main .tiny,
.area.base .tiny {
  width: 47.24770642%;
}
.area.main > .slim,
.area.base > .slim {
  width: 50%;
}
.area.main > .slim .foot,
.area.base > .slim .foot,
.area.main > .slim .part,
.area.base > .slim .part {
  margin-right: 2.75229358%;
  margin-left: 2.75229358%;
}
.area.main > .slim .part,
.area.base > .slim .part {
  width: 94.49541284%;
}
.area.main > .slim .tiny,
.area.base > .slim .tiny {
  width: 44.49541284%;
}
.area.main,
.area.base {
  width: 67.91277259%;
  margin-left: 16.04361371%;
}
.cb-layout3 .area.main {
  margin-top: 145px;
}
.cb-layout1 .north img {
  max-width: 193px !important;
}
.cb-layout1 .north .unit {
  padding: 80px 50px;
}
.cb-layout1 .north .unit:before {
  border-width: 20px;
}
.cb-layout1 .north .unit:after {
  right: -66px;
  width: 132px;
  height: 230px;
  transform: skewX(30deg);
}
.cb-layout1 .north .part {
  margin-top: 25px;
  margin-bottom: 25px;
}
.cb-layout2 .area.north,
.cb-layout3 .area.north {
  width: 67.91277259%;
  margin-left: 16.04361371%;
}
.cb-layout2 .area.north .unit.seam,
.cb-layout3 .area.north .unit.seam,
.cb-layout2 .area.north .unit.flat,
.cb-layout3 .area.north .unit.flat {
  padding: 80px 50px;
}
.cb-layout2 .area.north .unit.seam img,
.cb-layout3 .area.north .unit.seam img,
.cb-layout2 .area.north .unit.flat img,
.cb-layout3 .area.north .unit.flat img {
  max-width: 193px !important;
}
.cb-layout2 .area.north .unit.seam:before,
.cb-layout3 .area.north .unit.seam:before,
.cb-layout2 .area.north .unit.flat:before,
.cb-layout3 .area.north .unit.flat:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 20px solid transparent;
  transition: border 250ms;
}
.cb-layout2 .area.north .unit.seam:after,
.cb-layout3 .area.north .unit.seam:after {
  content: "";
  position: absolute;
  right: -6px;
  top: 0;
  width: 60px;
  height: 230px;
  background-color: #F64257;
  transform: skewX(30deg);
  box-shadow: -4px 3px 6px rgba(0, 0, 0, 0.27);
  opacity: 0;
  transition: opacity 250ms;
}
.cb-layout2 .area.north .unit.seam:hover:before,
.cb-layout3 .area.north .unit.seam:hover:before,
.cb-layout2 .area.north .unit.seam:focus:before,
.cb-layout3 .area.north .unit.seam:focus:before {
  border-color: #438273;
}
.cb-layout2 .area.north .unit.seam:hover:after,
.cb-layout3 .area.north .unit.seam:hover:after,
.cb-layout2 .area.north .unit.seam:focus:after,
.cb-layout3 .area.north .unit.seam:focus:after {
  opacity: 1;
}
.area.south .part,
.area.farwest .part {
  margin-top: 10px;
  margin-bottom: 10px;
}
.area.south .part.link,
.area.farwest .part.link {
  margin-top: 30px;
}
.area.south .part.tiny,
.area.farwest .part.tiny {
  width: 33%;
  padding: 0 12px;
}
.area.south .unit,
.area.farwest .unit {
  min-height: 884px;
  padding-bottom: 20px;
}
.area.south .unit .body,
.area.farwest .unit .body {
  width: 55.46108568%;
  max-width: 848px;
}
.unit.fold {
  padding: 0 15px;
}
.unit.form .name {
  padding-bottom: 12px;
}
.unit.form input,
.unit.form textarea {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.375;
}
.unit.form input.text,
.unit.form input.sign,
.unit.form input.password,
.unit.form textarea,
.unit.form input.hint,
.unit.form textarea.hint,
.unit.form input.capt {
  padding: 15px 25px;
}
.text-section--h2 {
  margin-bottom: 20px;
}
.text-section--h2 .north {
  margin-bottom: 15px;
}
.text-section--h3 {
  margin-bottom: 75px;
}
.farnorth .text-section--h3 {
  margin-bottom: 20px;
}
.north .text-section--h3 {
  margin-bottom: 25px;
}
.base .seam .text-section--loud .loud,
.main .seam .text-section--loud .loud,
.south .loud,
.farwest .loud {
  width: 630px;
  margin: 0 auto;
}
.farnorth .text-section--p {
  max-width: 436px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -642px;
  width: 1284px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*# sourceMappingURL=./screen-large.css.map */